<template>
    <div>
        <ul class="bg-teal-500 flex items-center justify-center">
            <li>
                <router-link class="p-4 block text-teal-50 hover:bg-teal-400" to="/customers/new"><i class="icon-users4 mr-3" style="font-size: 20px"></i>Add Customer</router-link>
            </li>
            <li>
                <router-link class="p-4 block text-teal-50 hover:bg-teal-400" to="/products/create"><i class="icon-stack2 mr-3" style="font-size: 20px"></i>Add Product</router-link>
            </li>
            <li>
                <router-link class="p-4 block text-teal-50 hover:bg-teal-400" to="/purchases/new"><i class="icon-stack mr-3" style="font-size: 20px"></i>Create Purchase Order</router-link>
            </li>
            <li>
                <router-link class="p-4 block text-teal-50 hover:bg-teal-400" to="/quotations/new"><i class="icon-price-tags2 mr-3" style="font-size: 20px"></i>Create Quotation</router-link>
            </li>
            <li>
                <router-link class="p-4 block text-teal-50 hover:bg-teal-400" to="/sales/new"><i class="icon-barcode2 mr-3" style="font-size: 20px"></i>Create Sales Order</router-link>
            </li>
        </ul>
        <div class="grid grid-cols-2 m-5 gap-5">
            <!-- TASK -->
            <div class="col-span-1 bg-white border rounded-sm">
                <div class="px-5 py-2 border-b">YOUR TASKS</div>
                <div class="grid grid-cols-4">
                    <div class="col-span-1 pl-5 pr-2 py-3 bg-cyan-500 text-cyan-50">DUE DATE</div>
                    <div class="col-span-1 px-2 py-3 bg-cyan-500 text-cyan-50">DOC #</div>
                    <div class="col-span-1 px-2 py-3 bg-cyan-500 text-cyan-50">TITLE</div>
                    <div class="col-span-1 pl-2 pr-5 py-3 bg-cyan-500 text-cyan-50">ACTION</div>
                </div>
                <div class=" max-h-80 h-80 overflow-y-auto">
                    <div v-for="(task, i) in tasks" :key="i" class="grid grid-cols-4 border-b">
                        <div class="col-span-1 pl-5 pr-2 py-3">{{getDate(task.dueDate)}}</div>
                        <router-link :to="task.route" class="col-span-1 px-2 py-3 text-teal-500">{{task.documentName}}</router-link>
                        <div class="col-span-1 px-2 py-3">{{task.title}}</div>
                        <div class="col-span-1 pl-2 pr-5 py-3">Detail</div>
                    </div>
                </div>
            </div>
            <div class="col-span-1">
                <!-- STAISTICT CUSTOMER & SUPPLIER -->
                <div class="grid grid-cols-2 gap-5 mb-5">
                    <div class="col-span-1">
                        <div class="bg-white border rounded-sm p-5 flex items-center relative">
                            <div>
                                <canvas id="customer"></canvas>
                            </div>
                            <div class="ml-auto text-right">
                                <div class="text-2xl font-semibold">{{Intl.NumberFormat('en-US').format(customerCount)}}</div>
                                <div class="text-xs text-gray-400">TOTAL CUSTOMER</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-span-1">
                        <div class="bg-white border rounded-sm p-5 flex items-center">
                            <div>
                                <canvas id="supplier"></canvas>
                            </div>
                            <div class="ml-auto text-right">
                                <div class="text-2xl font-semibold">{{Intl.NumberFormat('en-US').format(supplierCount)}}</div>
                                <div class="text-xs text-gray-400">TOTAL SUPPLIER</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bg-white border rounded-sm p-5">
                    <div class="flex justify-between">
                        <div class="flex items-center">
                            <a href="#" class="px-1.5 border-b-2 hover:text-teal-500">1D</a>
                            <a href="#" class="px-1.5 hover:text-teal-500">7D</a>
                            <a href="#" class="px-1.5 hover:text-teal-500">30D</a>
                        </div>
                        <div class="flex items-center">
                            <div class="flex items-center border border-blue-500 rounded p-1 text-blue-500 mr-3">
                                <div>
                                    <i class="icon-file-pdf" style="font-size: 32px"></i>
                                </div>
                                <div class="text-right ml-5">
                                    <div class="font-semibold text-base">{{quotations.length}}</div>
                                    <div class="text-xs">QUOTATIONS</div>
                                </div>
                            </div>
                            <div class="flex items-center border border-teal-500 rounded p-1 text-teal-500">
                                <div>
                                    <i class="icon-file-pdf" style="font-size: 32px"></i>
                                </div>
                                <div class="text-right ml-5">
                                    <div class="font-semibold text-base">{{sales.length}}</div>
                                    <div class="text-xs">TOTAL SALES</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5">
                        <canvas id="sale"></canvas>
                    </div>
                </div>
            </div>
            <!-- PRODUCT -->
        </div>
        <div class="bg-white p-5 rounded-sm border m-5">
            <div class="flex items-center justify-between">
                <div>PRODUCTS</div>
            </div>
            <canvas id="product" class="border"></canvas>
        </div>
        <div>
            <button @click="onUpdate">UPDATE</button>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import {moment} from '../../moment'
import Chart from 'chart.js/auto'
import breadcrumbsManager from '../../mixins/breadcrumbsManager.vue'
export default {
    mixins: [breadcrumbsManager],
    data () {
        return {
            tasks: [],
            supplierCount: 0,
            supplierLabel: [],
            supplierData: [],
            customerCount: 0,
            customerLabel: [],
            customerData: [],
            quotationLabel: [],
            quotations: [],
            sales: []
        }
    },
    created () {
        this.setButtonText()
        this.setDiscard(false)
        this.getData()
    },
    mounted () {
        let app = this
        var ctxCustomer = document.getElementById('customer')
        ctxCustomer.width = 50
        var ctxSupplier = document.getElementById('supplier')
        ctxSupplier.width = 50
       
        const getOrCreateTooltip = (chart) => {
            let tooltipEl = chart.canvas.parentNode.querySelector('div');

            if (!tooltipEl) {
                tooltipEl = document.createElement('div');
                tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
                tooltipEl.style.borderRadius = '3px';
                tooltipEl.style.color = 'white';
                tooltipEl.style.opacity = 1;
                tooltipEl.style.pointerEvents = 'none';
                tooltipEl.style.position = 'absolute';
                tooltipEl.style.transform = 'translate(-50%, 0)';
                tooltipEl.style.transition = 'all .1s ease';

                const table = document.createElement('table');
                table.style.margin = '0px';

                tooltipEl.appendChild(table);
                chart.canvas.parentNode.appendChild(tooltipEl);
                
            }

            return tooltipEl;
        };

        const externalTooltipHandler = (context) => {
            // Tooltip Element
            const {chart, tooltip} = context;
            const tooltipEl = getOrCreateTooltip(chart);

            // Hide if no tooltip
            if (tooltip.opacity === 0) {
                tooltipEl.style.opacity = 0;
                return;
            }

            // Set Text
            if (tooltip.body) {
                const titleLines = tooltip.title || [];
                const bodyLines = tooltip.body.map(b => b.lines);

                const tableHead = document.createElement('thead');

                titleLines.forEach(title => {
                const tr = document.createElement('tr');
                tr.style.borderWidth = 0;

                const th = document.createElement('th');
                th.style.borderWidth = 0;
                const text = document.createTextNode(title);

                th.appendChild(text);
                tr.appendChild(th);
                tableHead.appendChild(tr);
                });

                const tableBody = document.createElement('tbody');
                bodyLines.forEach((body, i) => {
                const colors = tooltip.labelColors[i];

                const span = document.createElement('span');
                span.style.background = colors.backgroundColor;
                span.style.borderColor = colors.borderColor;
                span.style.borderWidth = '2px';
                span.style.marginRight = '10px';
                span.style.height = '10px';
                span.style.width = '10px';
                span.style.display = 'inline-block';

                const tr = document.createElement('tr');
                tr.style.backgroundColor = 'inherit';
                tr.style.borderWidth = 0;

                const td = document.createElement('td');
                td.style.borderWidth = 0;

                const text = document.createTextNode(body);

                td.appendChild(span);
                td.appendChild(text);
                tr.appendChild(td);
                tableBody.appendChild(tr);
                });

                const tableRoot = tooltipEl.querySelector('table');

                // Remove old children
                while (tableRoot.firstChild) {
                tableRoot.firstChild.remove();
                }

                // Add new children
                tableRoot.appendChild(tableHead);
                tableRoot.appendChild(tableBody);
            }

            // const {offsetLeft: positionX} = chart.canvas;

            // Display, position, and set styles for font
                let a
                let b
                chart.canvas.addEventListener('mousemove', (event) => {
                    chart.canvas.style.cursor = 'pointer'
                    a = event.layerY
                    b = event.layerX
                     if (tooltip.opacity === 0) {
                        tooltipEl.style.opacity = 0;
                        return;
                    }
                    tooltipEl.style.opacity = 1;
                    tooltipEl.style.left = b + 75+ 'px';
                    tooltipEl.style.top =  a - 19 + 'px';
                    tooltipEl.style.font = tooltip.options.bodyFont.string;
                    tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
                })

        };
        // CUSTOMER STATS
        window.chartCustomer = new Chart(ctxCustomer, {
            type: 'doughnut',
            data: {
                labels: app.customerLabel,
                datasets: [{
                    data: app.customerData,
                    backgroundColor: [
                        'rgb(239,68,68)',
                        'rgb(245,158,11)',
                        'rgb(17,185,129)',
                        'rgb(5,182,211)',
                        'rgb(255, 205, 86)'
                    ],
                }]
            },
            options: {
                plugins: {
					legend: {
						display: false,
					},
                    tooltip: {
                        enabled: false,
                        position: 'nearest',
                        external: externalTooltipHandler
                    }
				},
            }
        })
        // SUPPLIER STATS
        window.chartSupplier = new Chart(ctxSupplier, {
            type: 'doughnut',
            data: {
                labels: app.supplierLabel,
                datasets: [{
                    data: app.supplierData,
                    backgroundColor: [
                        'rgb(255, 99, 132)',
                        'rgb(54, 162, 235)',
                        'rgb(255, 205, 86)',
                        'rgb(202,203,207)',
                        'rgb(153,102,255)',
                        'rgb(54,162,235)',
                        'rgb(236,72,153)',
                        'rgb(59,129,246)'
                    ],
                }]
            },
            options: {
                plugins: {
					legend: {
						display: false,
					},
                    tooltip: {
                        enabled: false,
                        position: 'nearest',
                        external: externalTooltipHandler
                    }
				},
            }
        })
        var ctxSales = document.getElementById('sale')
        ctxSales.height = 110
        new Chart (ctxSales, {
            type: 'line',
            data: {
                labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                datasets: [
                    {
                        data: [10, 12, 28, 49, 44, 32, 29, 34],
                        borderColor: 'rgb(20,184,166)',
                    },
                    {
                        data: [4, 1, 49, 0, 3, 44, 32, 29, 34],
                        borderColor: 'rgb(59,129,246)',
                    }
                ]
            },
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        display: false
                    }
                }
            }
        });
        var ctxProduct = document.getElementById('product')
        ctxProduct.height = 110
        new Chart (ctxProduct, {
            type: 'bar',
            data: {
                labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
                datasets: [
                    {
                        data: [10, 12, 28, 49, 44, 32, 29, 34],
                        borderColor: 'rgb(20,184,166)',
                    },
                ]
            },
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        display: false
                    }
                }
            }
        });
    },
    methods: {
        getData () {
            axios.get('/dashboard')
            .then(res => {
                console.log(res);
            })
        },
        getDate (d) {
            return moment(d)
        },
        onUpdate () {
            axios.put('/update')
        }
    }
}
</script>